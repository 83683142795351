.player-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 0px;
    overflow-x: scroll;
    overflow-y: auto;
}

.player-table th,
.player-table td {
    padding: .7vh;
    border: 1px solid #ddd;
    font-size: 14px;
    text-align: center;
}

.player-table th {
    background-color: #f2f2f2;
}

.player-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}

.player-table tbody tr:hover {
    background-color: #e0e0e0;
}

.player-table .player-cell {
    max-height: 9vh;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}

.player-table .projection-cell {
    max-width: 7vh;
    color: inherit;
    padding: 1px;
}

.player-table .ownership-cell {
    max-width: 6vh;
    color: inherit;
    padding: 1px;
}

.ownership-input,
.projection-input {
    width: 6vh;
    height: 2.8vh;
    text-align: center;
}

.lineup-card-cell {
    max-width: 100px;
    padding: 1px;
    text-align: center;
}